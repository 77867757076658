import React from "react";
import styled from "styled-components";
import * as constants from "../style/constants";

const StyledTitle = styled((props) => <div {...props} />)`
  color: ${constants.white};
  font-size: 55px;
  font-weight: ${constants.Titleweight};
  line-height: ${constants.TitleHeight};

  @media screen and (max-width: 413px) {
    font-size: ${constants.TitlefontsizeSP};
    font-weight: ${constants.TitleweightSP};
    margin-left: 0px;
    text-align: center;
  }
  @media screen and (min-width: 414px) and (max-width: 767px) {
    font-size: ${constants.TitlefontsizeP};
    font-weight: ${constants.TitleweightP};
    line-height: ${constants.TitleHeightP};
    padding-top: 0;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    font-size: ${constants.TitlefontsizeT};
    font-weight: ${constants.TitleweightT};
    line-height: ${constants.TitleHeightT};
    margin-left: 0;
    padding-top: 0;
  }
  @media screen and (min-width: 1024px) and (max-width: 1500px) {
    font-size: ${constants.TitlefontsizeT};
    font-weight: ${constants.TitleweightT};
    line-height: ${constants.TitleHeightT};
    margin-left: ${constants.ContentLmarginT};
  }
  @media screen (min-width: 1501px) {
    font-size: ${constants.TitlefontsizeT};
    font-weight: ${constants.TitleweightT};
    line-height: ${constants.TitleHeightT};
    margin-left: ${constants.ContentLmarginT};
  }
`;
const TitleCard = ({ children, style }) => (
  <StyledTitle style={style}>{children}</StyledTitle>
);
export default TitleCard;
