import React from "react";
import styled from "styled-components";
import * as constants from "../style/constants";
import Logoimg from "../images/UElogowhite.png";
import { Link } from "gatsby";
import MediaQuery from "react-responsive";

const Footer = styled.div`
  background-color: ${constants.black};
  ${constants.SmallPhone} {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
  }
  ${constants.Phone} {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
  }
  ${constants.Tablet} {
    display: flex;
    flex-direction:column;
    align-items: center;
    padding-top: 40px;
  }
  ${constants.Desktop} {
    display: flex;
    flex-direction:column;
    align-items: center;
    padding-top: 40px;
  }
`;
const LSdiv = styled.div`
  background-color: ${constants.black};
  width:90%;
  display:flex;
  flex-direction:row;
  justify-content:space-around;
  ${constants.Desktop} {
  width:80%;
  }
`
const Logo = styled.img`
  ${constants.SmallPhone} {
    width: 150px;
    height: 64px;
    padding-bottom: 60px;
  }
  ${constants.Phone} {
    width: 150px;
    height: 64px;
    padding-bottom: 60px;
  }
  ${constants.Tablet} {
    width: 150px;
    height: 64px;
    padding-bottom: 60px;
  }
  ${constants.Laptop} {
    width: 150px;
    height: 64px;
    padding-bottom: 60px;
  }
  ${constants.Desktop} {
    width: 150px;
    height: 64px;
    padding-bottom: 60px;
  }
`;
const FooterLink = styled((props) => <Link {...props} />)`
  ${constants.SmallPhone} {
    text-decoration: none;
    color: ${constants.white};
    justify-self: center;
    font-size: 18px;
    line-height: 50px;
  }
  ${constants.Phone} {
    text-decoration: none;
    color: ${constants.white};
    justify-self: center;
    font-size: 18px;
    line-height: 50px;
  }
  ${constants.Tablet} {
    text-decoration: none;
    color: ${constants.white};
    justify-self: center;
    font-size: 18px;
    line-height: 50px;
  }
  ${constants.Laptop} {
    text-decoration: none;
    color: ${constants.white};
    justify-self: center;
    font-size: 18px;
    line-height: 35px;
  }
  ${constants.Desktop} {
    text-decoration: none;
    color: ${constants.white};
    justify-self: center;
    font-size: 18px;
    line-height: 35px;
  }
`;
const Copyright = styled.div`
  color: ${constants.white};
  justify-self: center;
  font-family: ${constants.Cfont}, ${constants.Dfont};
  width: 100%;
  text-align: center;
  margin-top: 15px;
  padding-top: 15px;
  margin-bottom: 15px;
  font-size: 12px;
  border-top: 1px solid ${constants.offBlack};
`;
const Address = styled.div`
  color: ${constants.white};
  justify-self: center;
  font-size: 18px;
  line-height: 30px;
  width: 80%;
  text-align: center;
  font-family: ${constants.Cfont}, ${constants.Dfont};
  margin-bottom: 15px;
  ${constants.Laptop} {
  width: 100%;
  text-align:left;
  }
`;
const Heading = styled.h3`
  font-size: 22px;
  font-family: ${constants.Cfont}, ${constants.Dfont};
  color: ${constants.white};
  line-height: 30px;
`;
export default function footer() {
  return (
    <Footer>
      <MediaQuery minWidth={320} maxWidth={374}>
        <Logo src={Logoimg} />
        <Heading>公司信息</Heading>
        <Address>
          美国办公室
          <br />
          Pearson court square Long island city, NY, 11101
        </Address>
        <br />
        <Address>
          加拿大办公室
          <br />
          503-889 Bay Street
          <br />
          Toronto, Ontario, M5S 3K5
          <br />
          +1 (647) 975-5642
          <br />
          Contact@nativue.com
        </Address>
        <FooterLink to={"/contactus"}>联系我们</FooterLink>
        <FooterLink to={"/"}>服务提供</FooterLink>
        <FooterLink to={"/process"}>工作过程</FooterLink>
        <FooterLink to={"/team"}>团队简介</FooterLink>
        <Copyright>版权所有 2021 UE Translations，LLC.保留所有权利。</Copyright>
      </MediaQuery>
      <MediaQuery minWidth={375} maxWidth={767}>
        <Logo src={Logoimg} />
        <Heading>公司信息</Heading>
        <Address>
          美国办公室
          <br />
          Pearson court square Long island city, NY, 11101
        </Address>
        <br />
        <Address>
          加拿大办公室
          <br />
          503-889 Bay Street
          <br />
          Toronto, Ontario, M5S 3K5
          <br />
          +1 (647) 975-5642
          <br />
          Contact@nativue.com
        </Address>
        <FooterLink to={"/contactus"}>联系我们</FooterLink>
        <FooterLink to={"/"}>服务提供</FooterLink>
        <FooterLink to={"/process"}>工作过程</FooterLink>
        <FooterLink to={"/team"}>团队简介</FooterLink>
        <Copyright>版权所有 2021 UE Translations，LLC.保留所有权利。</Copyright>
      </MediaQuery>
      <MediaQuery minWidth={768} maxWidth={1023}>
        <Logo src={Logoimg} />
        <Heading>公司信息</Heading>
        <Address>
          美国办公室
          <br />
          Pearson court square Long island city, NY, 11101
        </Address>
        <br />
        <Address>
          加拿大办公室
          <br />
          503-889 Bay Street
          <br />
          Toronto, Ontario, M5S 3K5
          <br />
          +1 (647) 975-5642
          <br />
          Contact@nativue.com
        </Address>
        <FooterLink to={"/contactus"}>联系我们</FooterLink>
        <FooterLink to={"/"}>服务提供</FooterLink>
        <FooterLink to={"/process"}>工作过程</FooterLink>
        <FooterLink to={"/team"}>团队简介</FooterLink>
        <Copyright>版权所有 2021 UE Translations，LLC.保留所有权利。</Copyright>
      </MediaQuery>
      <MediaQuery minWidth={1024} maxWidth={1439}>
        <LSdiv>
        <Logo src={Logoimg} />
        <div style={{width:'20%', textAlign:'left'}}>
        <Address>
          美国办公室
          <br />
          Pearson court square Long island city, NY, 11101
        </Address>
      </div>
        <div style={{width:'20%'}}>
        <Address>
          加拿大办公室
          <br />
          503-889 Bay Street
          <br />
          Toronto, Ontario, 
          <br />
          M5S 3K5
          <br />
          +1 (647) 975-5642
          <br />
          Contact@nativue.com
        </Address>
      </div>
      <div style={{display:'flex', flexDirection:'column'}}>
        <FooterLink to={"/contactus"}>联系我们</FooterLink>
        <FooterLink to={"/"}>服务提供</FooterLink>
        <FooterLink to={"/process"}>工作过程</FooterLink>
        <FooterLink to={"/team"}>团队简介</FooterLink>
      </div>
        </LSdiv>
        <Copyright>版权所有 2021 UE Translations，LLC.保留所有权利。</Copyright>
      </MediaQuery>
      <MediaQuery minWidth={1440}>
        <LSdiv>
        <Logo src={Logoimg} />
        <div style={{width:'20%', textAlign:'left'}}>
        <Address>
          美国办公室
          <br />
          Pearson court square Long island city, NY, 11101
        </Address>
      </div>
        <div style={{width:'20%'}}>
        <Address>
          加拿大办公室
          <br />
          503-889 Bay Street
          <br />
          Toronto, Ontario, 
          <br />
          M5S 3K5
          <br />
          +1 (647) 975-5642
          <br />
          Contact@nativue.com
        </Address>
      </div>
      <div style={{display:'flex', flexDirection:'column'}}>
        <FooterLink to={"/contactus"}>联系我们</FooterLink>
        <FooterLink to={"/"}>服务提供</FooterLink>
        <FooterLink to={"/process"}>工作过程</FooterLink>
        <FooterLink to={"/team"}>团队简介</FooterLink>
      </div>
        </LSdiv>
        <Copyright>版权所有 2021 UE Translations，LLC.保留所有权利。</Copyright>
      </MediaQuery>
    </Footer>
  );
}
